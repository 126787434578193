import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { registerIVSTech } from 'amazon-ivs-player';

const wasmWorkerPath = 'ivs/amazon-ivs-wasmworker.min.js'
const wasmBinaryPath = 'ivs/amazon-ivs-wasmworker.min.wasm'
const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady, streamUrl} = props;

    registerIVSTech(videojs, {
        wasmWorker: createAbsolutePath(wasmWorkerPath),
        wasmBinary: createAbsolutePath(wasmBinaryPath),
    });

    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');

                player.src(streamUrl);
                player.play();

                onReady && onReady(player);
            });
        }
    }, [options, videoRef]);

    return (
        <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' style={{width: '100%', height: '100%'}}></video>
        </div>
    );
}

export default VideoJS;
